var _a;
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { authSlice } from "../common/authSlice";
import { backendApi } from "../api/api";
import { profileApi } from "../api/profileApi";
import { tagApi } from "../api/tagApi";
export var store = configureStore({
    reducer: (_a = {},
        _a[authSlice.name] = authSlice.reducer,
        _a[backendApi.reducerPath] = backendApi.reducer,
        _a[profileApi.reducerPath] = profileApi.reducer,
        _a[tagApi.reducerPath] = tagApi.reducer,
        _a),
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware().concat(backendApi.middleware, profileApi.middleware, tagApi.middleware);
    },
});
export var useAppDispatch = function () { return useDispatch(); };
export var useAppSelector = useSelector;
