export var ROLE_MAPPING = {
    "pricer-admin": [
        "tag:read",
        "tag:create",
        "tag:update",
        "pricer:employee",
        "store:create",
        "store:update",
        "unassigned-store:all",
    ],
    admin: ["tag:read", "tag:create", "tag:update"],
    "pricer-editor": [
        "tag:read",
        "tag:create",
        "tag:update",
        "pricer:employee",
        "store:create",
        "store:update",
        "store:read",
        "unassigned-store:all",
    ],
    editor: ["tag:read", "tag:create", "tag:update"],
    "pricer-viewer": [],
    "pricer-profile": [],
};
