var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getConfig } from "../common/config";
export var responseHandler = function (response) {
    if (response.status === 401 || response.status === 403) {
        return Promise.resolve({ error: response.statusText });
    }
    return Promise.resolve(response.json());
};
export var fetchChildren = function (tenant) {
    var children = [];
    tenant.links.forEach(function (link) {
        var childTenant = fetchChildren(link);
        var _loop_1 = function (aChild) {
            var isDuplicateAllChild = children.find(function (child) { return child.id === aChild.id; });
            if (!isDuplicateAllChild) {
                children.push(aChild);
            }
        };
        //Make sure recursive call does not add child which already exist
        for (var _i = 0, _a = childTenant.allChildren; _i < _a.length; _i++) {
            var aChild = _a[_i];
            _loop_1(aChild);
        }
        //Make sure link is only added if needed.
        var isDuplicate = children.find(function (child) { return child.id === link.id; });
        if (!isDuplicate) {
            children.push(link);
        }
    });
    tenant.allChildren = children;
    return tenant;
};
export var profileApi = createApi({
    reducerPath: "profileApi",
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().profileApi,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set("authorization", "Bearer ".concat(token));
            }
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    tagTypes: ["Profiles", "Profile"],
    endpoints: function (builder) { return ({
        getProfile: builder.query({
            query: function () { return ({
                url: "api/v2/profile",
                method: "GET",
                responseHandler: responseHandler,
            }); },
        }),
        getTenants: builder.query({
            query: function (id) { return "/api/v1/profile/tenants"; },
            transformResponse: function (response) {
                var tenant = fetchChildren(response);
                return __spreadArray([tenant], tenant.allChildren, true);
            },
            providesTags: function (result, error, id) { return ["Profiles"]; },
        }),
    }); },
});
export var useGetProfileQuery = profileApi.useGetProfileQuery, useGetTenantsQuery = profileApi.useGetTenantsQuery;
