import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate, Route, Routes } from "react-router";
import { StoresPage } from "./views/Stores/StoresPage";
import { lazy } from "react";
import Auth from "./Auth";
import { useAuthentication } from "./common/hooks/authentication";
var paths = ["/stores", "/unassigned-stores", "/store-groups"];
export var isPath = function (path) {
    return paths.includes(path);
};
var StoreList = lazy(function () { return import("./views/Stores/components/organisms/ListStoresView"); });
var EditStore = lazy(function () { return import("./views/Stores/components/organisms/EditStoreView"); });
var CreateStore = lazy(function () { return import("./views/Stores/components/organisms/CreateStoreView"); });
var EditUnassignedStore = lazy(function () {
    return import("./views/UnassignedStores/components/organisms/EditUnassignedStoreView");
});
var UnassignedStoreList = lazy(function () {
    return import("./views/UnassignedStores/components/organisms/UnassignedStoreListView");
});
var TagView = lazy(function () { return import("./views/StoreGroups/components/organisms/StoreGroupView"); });
var NotFoundPage = lazy(function () { return import("./NotFoundPage"); });
export var ApplicationRoutes = function () {
    var has = useAuthentication().has;
    return (_jsxs(Routes, { children: [_jsxs(Route, { path: '/stores', element: _jsx(StoresPage, {}), children: [_jsx(Route, { index: true, element: _jsx(StoreList, {}) }), _jsx(Route, { path: ':id', element: _jsx(EditStore, {}) }), _jsx(Route, { path: 'create', element: _jsx(CreateStore, {}) })] }, "stores"), _jsxs(Route, { path: '/unassigned-stores', element: _jsx(Auth, { hasPermission: function () { return has("unassigned-store:all"); } }), children: [_jsx(Route, { index: true, element: _jsx(UnassignedStoreList, {}) }), _jsx(Route, { path: ':id', element: _jsx(EditUnassignedStore, {}) })] }), _jsx(Route, { path: '/store-groups', element: _jsx(Auth, { hasPermission: function () { return has("tag:update") && has("tag:create"); } }), children: _jsx(Route, { path: '', element: _jsx(TagView, {}), index: true }) }), _jsx(Route, { path: '/404', element: _jsx(NotFoundPage, {}) }), _jsx(Route, { path: '*', element: _jsx(Navigate, { to: '/stores' }) }, "root")] }));
};
