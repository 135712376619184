import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
var initialState = {
    isAuthenticated: false,
    token: undefined,
    role: "profile",
    parentTenant: "",
};
export var parseToken = function (token) { return jwtDecode(token); };
export var getRole = function (token) { return token["https://pricer.com/role"]; };
export var getTenant = function (token) { return token["https://pricer.com/tenant"]; };
export var authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        addToken: function (state, action) {
            var decoded = parseToken(action.payload);
            state.token = action.payload;
            state.isAuthenticated = true;
            state.tenantId = getTenant(decoded);
            state.role = getRole(decoded);
            state.parentTenant = decoded["https://pricer.com/parent"];
        },
    },
});
