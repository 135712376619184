var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createApi, fetchBaseQuery, } from "@reduxjs/toolkit/query/react";
import { getConfig } from "../common/config";
export function getApiStatusCode(error) {
    if (!error) {
        return 0;
    }
    var fetchError = error;
    if (fetchError.status === undefined) {
        return -1;
    }
    if (typeof fetchError.status === "number") {
        return fetchError.status;
    }
    if (fetchError.status === "PARSING_ERROR") {
        return fetchError.originalStatus;
    }
    return -1;
}
export var isAccessDenied = function (error) { return [401, 403].includes(getApiStatusCode(error)); };
var responseHandler = function (response) {
    if (response.status === 401 || response.status === 403) {
        return Promise.resolve({ error: response.statusText });
    }
    return Promise.resolve(response.json());
};
var transformPaginatedResponse = function (apiResponse, meta, arg) {
    return {
        pageSize: Number(arg.pageSize),
        totalElements: Number(meta.response.headers.get("X-Total-Elements")),
        totalPages: Number(meta.response.headers.get("X-Total-Pages")),
        data: apiResponse,
    };
};
export var backendApi = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().apiBase,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set("authorization", "Bearer ".concat(token));
            }
            else {
                console.error("No token found in state, can't make API call");
            }
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    tagTypes: ["Stores", "Store", "UnassignedStore", "UnassignedStores"],
    endpoints: function (builder) { return ({
        getStores: builder.query({
            query: function (_a) {
                var _b = _a.pageSize, pageSize = _b === void 0 ? 10 : _b, _c = _a.page, page = _c === void 0 ? 0 : _c, _d = _a.search, search = _d === void 0 ? "" : _d, _e = _a.typeFilter, typeFilter = _e === void 0 ? [] : _e, _f = _a.sizeFilter, sizeFilter = _f === void 0 ? [] : _f, _g = _a.categoryFilter, categoryFilter = _g === void 0 ? "" : _g;
                return ({
                    url: (function () {
                        var path = "/v3/stores?page=".concat(page, "&size=").concat(pageSize, "&search=").concat(search);
                        typeFilter.forEach(function (type) { return (path = path.concat("&type=".concat(type))); });
                        sizeFilter.forEach(function (size) { return (path = path.concat("&size_filter=".concat(size))); });
                        if (categoryFilter && categoryFilter !== "ALL") {
                            path = path.concat("&category=".concat(categoryFilter));
                        }
                        return path;
                    })(),
                    responseHandler: responseHandler,
                });
            },
            providesTags: ["Stores"],
            transformResponse: (transformPaginatedResponse),
        }),
        getStore: builder.query({
            query: function (id) { return ({
                url: "/v1/stores/".concat(id),
                responseHandler: responseHandler,
            }); },
            providesTags: function (result, error, id) { return [{ type: "Store", id: id }]; },
        }),
        getAddress: builder.query({
            query: function (search) { return ({
                url: "/v1/places/search?address=".concat(search),
                responseHandler: responseHandler,
            }); },
        }),
        getAddresses: builder.query({
            query: function (query) { return ({
                url: "/v1/places/autocomplete?search=".concat(query),
                responseHandler: responseHandler,
            }); },
        }),
        getUnassignedStores: builder.query({
            query: function (_a) {
                var _b = _a.pageSize, pageSize = _b === void 0 ? 10 : _b, _c = _a.page, page = _c === void 0 ? 0 : _c, _d = _a.search, search = _d === void 0 ? "" : _d, _e = _a.typeFilter, typeFilter = _e === void 0 ? [] : _e, _f = _a.sizeFilter, sizeFilter = _f === void 0 ? [] : _f, _g = _a.categoryFilter, categoryFilter = _g === void 0 ? "" : _g;
                return ({
                    url: (function () {
                        var path = "/v2/unassigned-stores?page=".concat(page, "&size=").concat(pageSize, "&search=").concat(search);
                        typeFilter.forEach(function (type) { return (path = path.concat("&type=".concat(type))); });
                        sizeFilter.forEach(function (size) { return (path = path.concat("&size_filter=".concat(size))); });
                        if (categoryFilter && categoryFilter !== "ALL") {
                            path = path.concat("&category=".concat(categoryFilter));
                        }
                        return path;
                    })(),
                    responseHandler: responseHandler,
                });
            },
            providesTags: ["UnassignedStores"],
            transformResponse: (transformPaginatedResponse),
        }),
        getUnassignedStore: builder.query({
            query: function (id) { return ({
                url: "/v1/unassigned-stores/".concat(id),
                responseHandler: responseHandler,
            }); },
            providesTags: function (result, error, id) { return [{ type: "UnassignedStore", id: id }]; },
        }),
        saveStore: builder.mutation({
            query: function (store) { return ({
                url: "/v1/stores/".concat(store.id),
                method: "PUT",
                body: __assign(__assign({}, store), { id: undefined }),
            }); },
            invalidatesTags: function (result, error, _a) {
                var id = _a.id;
                return [
                    { type: "Store", id: id },
                    { type: "Stores" },
                ];
            },
        }),
        saveUnassignedStore: builder.mutation({
            query: function (store) { return ({
                url: "/v1/unassigned-stores/".concat(store.id),
                method: "PUT",
                body: __assign(__assign({}, store), { id: undefined }),
            }); },
            invalidatesTags: function (result, error, _a) {
                var id = _a.id;
                return [
                    { type: "UnassignedStore", id: id },
                    { type: "UnassignedStores" },
                ];
            },
        }),
        assignStore: builder.mutation({
            query: function (_a) {
                var _b = _a.storeId, storeId = _b === void 0 ? "" : _b, _c = _a.tenantId, tenantId = _c === void 0 ? "" : _c;
                return ({
                    url: "/v1/unassigned-stores/".concat(storeId, "/tenant/").concat(tenantId),
                    method: "POST",
                    body: {},
                });
            },
            invalidatesTags: function (result, error, _a) {
                var storeId = _a.storeId;
                return [
                    { type: "UnassignedStore", storeId: storeId },
                    { type: "UnassignedStores" },
                    { type: "Store", storeId: storeId },
                    { type: "Stores" },
                ];
            },
        }),
        deleteUnassignedStore: builder.mutation({
            query: function (_a) {
                var _b = _a.storeId, storeId = _b === void 0 ? "" : _b;
                return ({
                    url: "/v1/unassigned-stores/".concat(storeId),
                    method: "DELETE",
                    body: {},
                });
            },
            invalidatesTags: function (result, error, _a) {
                var storeId = _a.storeId;
                return [
                    { type: "UnassignedStore", storeId: storeId },
                    { type: "UnassignedStores" },
                ];
            },
        }),
        createStore: builder.mutation({
            query: function (store) { return ({
                url: "/v1/stores",
                method: "POST",
                body: __assign({}, store),
            }); },
            invalidatesTags: ["Stores"],
        }),
        createRegistrationKey: builder.mutation({
            query: function (id) { return ({
                url: "/v1/stores/".concat(id, "/access-code-key"),
                method: "POST",
            }); },
        }),
        downloadOnPremLicense: builder.query({
            query: function (id) { return ({
                url: "/v1/stores/".concat(id, "/license"),
                method: "GET",
            }); },
        }),
    }); },
});
export var useGetAddressesQuery = backendApi.useGetAddressesQuery, useGetAddressQuery = backendApi.useGetAddressQuery, useLazyGetAddressQuery = backendApi.useLazyGetAddressQuery, useGetStoresQuery = backendApi.useGetStoresQuery, useGetStoreQuery = backendApi.useGetStoreQuery, useLazyGetStoresQuery = backendApi.useLazyGetStoresQuery, useGetUnassignedStoresQuery = backendApi.useGetUnassignedStoresQuery, useGetUnassignedStoreQuery = backendApi.useGetUnassignedStoreQuery, useSaveUnassignedStoreMutation = backendApi.useSaveUnassignedStoreMutation, useSaveStoreMutation = backendApi.useSaveStoreMutation, useAssignStoreMutation = backendApi.useAssignStoreMutation, useDeleteUnassignedStoreMutation = backendApi.useDeleteUnassignedStoreMutation, useCreateStoreMutation = backendApi.useCreateStoreMutation, useCreateRegistrationKeyMutation = backendApi.useCreateRegistrationKeyMutation, useLazyDownloadOnPremLicenseQuery = backendApi.useLazyDownloadOnPremLicenseQuery;
